import React from 'react';
import { FormControl } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import * as Styled from './SearchFieldStyles'

const SearchField = ({id, value, placeholder="", onChange, onClick}: SearchFieldProps) => (
  <FormControl variant="outlined">
    <Styled.CustomOutlinedInput startAdornment={<SearchIcon />} id={id} value={value} onClick={onClick} onChange={onChange} placeholder={placeholder}/>
  </FormControl>
);

export default SearchField;
