import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StyledWrapperUserMenu = styled('div')({
	display: 'flex',
	alignItems: 'center',
});

export const SignInButton = styled(Button)(({ theme }) => ({
	width: 144,
	marginRight: 20,
	padding: 12,
	borderColor: theme.palette.primary.main,
	fontSize: '0.75rem',
	fontWeight: 600,
	letterSpacing: '0.875px',
	marginLeft: 8,
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},

	[theme.breakpoints.down('md')]: {
		width: 124,
		margin: 0,
	},
}))

export const SignInLoadingButton = styled(SignInButton)(({ theme }) => ({
	paddingLeft: 25,
	borderColor: `${theme.palette.primary.main} !important`,
	color: `${theme.palette.primary.main} !important`,
	marginLeft: '8px',
	'&:hover': {
		backgroundColor: `${theme.palette.primary.main} !important`,
		color: `${theme.palette.common.white} !important`,
	},
	'#signInLoadingIcon>div': {
		color: `${theme.palette.primary.main} !important`,
	},
	'&:hover #signInLoadingIcon>div': {
		color: `${theme.palette.common.white} !important`,
	}
}))