const AppRouteNames = {
  home: '/',
  learn: '/learn/:courseSlug',
  course: '/course/:courseSlug',
  topic: '/course/:courseSlug/topic/:topicSlug',
  // If your on localhost then it tries to feed in localhost/redirect, this will fail in prod as its insecure and needs to be enabled on our API.
  logout: `${process.env.REACT_APP_SECDIM_ID_FRONTEND}account/logout`,
  settingsID: `${process.env.REACT_APP_SECDIM_ID_FRONTEND}account/settings`, // send them to settings where they can do it.
};

export default AppRouteNames;
