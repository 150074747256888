import { useCallback, useEffect, useMemo, useState } from 'react';
import { codeResponse, OpenIDContext } from '../../api/openid';
import learnBackendRequestHandler from '../../api/requestHandler';

const OpenID = ({ children }: DefaultProps) => {
	const subscriptionCheckout = new URLSearchParams(window.location.search).get('subscription-checkout')
	const code = new URLSearchParams(window.location.search).get('code')
	const [profile, setProfile] = useState({
		authenticated: false,
		profile: {
			id: '',
			username: '',
			email: '',
			firstName: '',
			lastName: '',
			isSubscribed: false
		},
	})

	const heartbeat = useCallback(async () => {
		if (code) {
			codeResponse(window.location.href)
				.then(resp => {
					const idToken = resp.id_token // User's 'passport'
					const newURL = window.location.href.split('?')[0]
					window.history.pushState('object', document.title, newURL)
					learnBackendRequestHandler('login', undefined, idToken).then(response => {
						if ((response.data as any).success) {
							window.location.href =
								sessionStorage.getItem('redirectURI') || window.location.origin
							sessionStorage.removeItem('redirectURI')
						}
					})
				})
				.catch(err => {
					// eslint-disable-next-line no-console
					console.error(err)
				})
		}
		learnBackendRequestHandler('heartBeat').then(hbResp1 => {
			if ((hbResp1.data as any).is_logged_in) {
				learnBackendRequestHandler('userInfo').then(userInfoResponse => {
					if ((userInfoResponse.data as any).username) {
						if (sessionStorage.getItem('redirectURI') && subscriptionCheckout) {
							sessionStorage.setItem("subscriptionCheckout", subscriptionCheckout)
							window.location.href = sessionStorage.getItem('redirectURI')!
							sessionStorage.removeItem("redirectURI")
						}
						setProfile({
							authenticated: true,
							profile: {
								id: (userInfoResponse.data as any).id,
								username: (userInfoResponse.data as any).username,
								firstName: (userInfoResponse.data as any).first_name,
								lastName: (userInfoResponse.data as any).last_name,
								email: (userInfoResponse.data as any).email,
								isSubscribed: (userInfoResponse.data as any).subscription
							}
						})
					}
				})
			}
		})
	}, [code, subscriptionCheckout])

	useEffect(() => {
		heartbeat()
	}, [heartbeat])

	const valueOfOpenIDContext = useMemo(() => ({ authenticated: profile.authenticated, profile: profile.profile }), [profile.authenticated, profile.profile]);
	return (
		<OpenIDContext.Provider value={valueOfOpenIDContext}>
			{children}
		</OpenIDContext.Provider>
	)
}
export default OpenID