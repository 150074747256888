/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ReduxCustomCourse {
	course: Course
	mergedTopics: MergedTopics[],
}

const initialState: ReduxCustomCourse[] = []
const courseSlice = createSlice({
	name: 'CourseSlice',
	initialState,
	reducers: {
		addCourse: (state, action: PayloadAction<{ course: Course }>) => {
			const course = state.find((item: ReduxCustomCourse) => item.course.slug === action.payload.course.slug)
			if (!course) {
				const mergedTopics: { title: string, topics: Topic[] }[] = []
				// loop through each topic and merge the common topic with same topic title and place a topic index for each topic
				// Note: if the topics are same with same title, then their index will be same
				action.payload.course.topics.forEach((topic: Topic) => {
					const mergeTopic = mergedTopics.find((mergedTopic: MergedTopics) => mergedTopic.title === topic.title)
					if (mergeTopic) {
						mergeTopic.topics.push({
							...topic,
							topicIndex: mergeTopic.topics[0].topicIndex // topic index are same since their title or they are same topic
						})
					}
					else {
						mergedTopics.push(
							{
								title: topic.title,
								topics: [{
									...topic,
									topicIndex: mergedTopics.length < 10 ?
										`0${mergedTopics.length === 0 ? 0 : mergedTopics.length}`
										:
										`${(mergedTopics.length - 1)}`
								}],
							}
						)
					}
				})
				// the following iteration is for checking the similar topics and find out any of the similar topic is viewed by user, if it is viewed then make the most first similar topic item's property => is_topic_viewed_by_user is true
				mergedTopics.forEach((mergedTopic: MergedTopics) => {
					if (mergedTopic.topics.length > 0) {
						const viewedTopic = mergedTopic.topics.find((topic: Topic) => topic.is_topic_viewed_by_user)
						if (viewedTopic)
							// eslint-disable-next-line no-param-reassign
							mergedTopic.topics[0].is_topic_viewed_by_user = true;
					}
				})
				state.push({
					course: action.payload.course,
					mergedTopics,
				})
			}
			return state
		},

		updateCourseTopic: (state, action: PayloadAction<{ courseSlug: string, topic: Topic }>) => {
			const course = state.find((item: ReduxCustomCourse) => item.course.slug === action.payload.courseSlug)
			course?.mergedTopics.forEach((mergedTopic: MergedTopics) => {
				if (mergedTopic.title === action.payload.topic.title) {
					mergedTopic.topics.forEach((topic: Topic) => {
						if (topic.slug === action.payload.topic.slug) {
							topic.is_topic_viewed_by_user = action.payload.topic.is_topic_viewed_by_user
							topic.file_content = action.payload.topic.file_content
							topic.git = action.payload.topic.git
						}
					})
					/** make the first item of the merged topic list as viewed by user, because for example,
						[topic1, topic2, topic3] all are same topics with same title but different labs
						now, in case user directly views topic2 or topic3, then make the topic1 is also viewed by default, 
						this is because they are merged in the same queue and is being done to manage tick sign in the course content list */
					mergedTopic.topics[0].is_topic_viewed_by_user = action.payload.topic.is_topic_viewed_by_user
				}
			})
		}
	},
});
export const { addCourse, updateCourseTopic } = courseSlice.actions;
export default courseSlice.reducer;
