import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';

export const MenuButton = styled(Button)({
	marginRight: '14px',
	padding: 0,
	minWidth: 'auto',

	'&:focus': {
		outline: 'none',
	},

	'&:hover': {
		backgroundColor: 'transparent'
	}
});

export const CustomMenu = styled(Menu)({
	zIndex: 1500
})

export const CustomMenuItem = styled(MenuItem)({
	padding: '0 !important'
})

export const MenuLink = styled(Link)({
	width: '100%',
	height: '100%',
	textDecoration: 'none',
	color: '#000',
	padding: '4px 16px',
})

export const UserNavLink = styled('a')({
	width: '100%',
	height: '100%',
	padding: '4px 16px',
	color: 'rgba(0, 0, 0, 0.87)',
	textDecoration: 'none',
})