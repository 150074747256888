export const urls = {
    profile: {
        endpoint: `api/v2/user`,
        type: 'GET'
    },
    courses: {
        endpoint: `api/v2/courses/`,
        type: 'GET'
    },
    course: {
        endpoint: `api/v2/course/`,
        type: 'GET'
    },
    topic: {
        endpoint: `api/v2/course/`,
        type: 'GET'
    },
    heartBeat: {
        endpoint: `api/v2/account/hb/`,
        type: 'GET'
    },
    userInfo: {
        endpoint: `api/v2/userinfo/`,
        type: 'GET'
    },
    login: {
        endpoint: `api/v2/account/login/`,
        type: 'POST'
    },
    logout: {
        endpoint: `api/v2/account/logout/`,
        type: 'POST'
    },
    repository: {
        endpoint: `api/v2/course/`,
        type: 'GET'
    },
    openIdClient: {
        endpoint: 'api/v2/ocid/',
        type: 'GET'
    },
}

// deleteEnrollments: (id: string) => ({
//     endpoint: `api/v2/user/syllabus/${id}/delete`,
//     type: 'GET'
// }),

// repository: (id: string) => ({
//     endpoint: `api/v2/topic/${id}/repository/url`,
//     type: 'GET'
// }),

// topicPost: (slug: string) => ({
//     endpoint: `api/v2/topics/${slug}`,
//     type: 'POST'
// }),

// syllabus: (topicId: string, courseId: string) => ({
//     endpoint: `api/v2/syllabus?topic_id=${topicId}&course_id=${courseId}`,
//     type: 'GET'
// }),

// coursePost: (slug: string) => ({
//     endpoint: `api/v2/courses/${slug}`,
//     type: 'POST'
// }),

// filterCourses: (query: string) => ({
//     endpoint: `api/v2/courses/?search=${query}`,
//     type: 'GET'
// }),
export default urls