import { useCallback, useState, useContext } from 'react';
import localforage from 'localforage';
import { Typography } from '@mui/material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AppRouteNames from '../../constants/route';
import { OpenIDContext } from '../../api/openid'
import * as Styled from './UserMenuStyles'
import learnBackendRequestHandler from '../../api/requestHandler';

// Perhaps we need to make dynamic menu
const UserMenu = () => {
    const { profile } = useContext(OpenIDContext)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback(
        (event: any) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const handleLogout = useCallback(() => {
        learnBackendRequestHandler("logout").then(() => {
            localStorage.clear()
            localforage.clear()
            sessionStorage.clear()
            handleClose();
            window.location.href = AppRouteNames.logout
        })
    }, [handleClose]);

    return (
        <>
            <Styled.MenuButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                disableRipple
            >
                <AccountCircleOutlinedIcon color="primary" fontSize="large" />
            </Styled.MenuButton>
            <Styled.CustomMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Styled.CustomMenuItem disabled>
                    <Typography sx={{ padding: '4px 16px' }}>Hi {profile.username}!</Typography>
                </Styled.CustomMenuItem>
                <Styled.CustomMenuItem onClick={handleClose}>
                    <Styled.UserNavLink
                        href={`${AppRouteNames.settingsID}`}
                        target='_blank'
                    >
                        Account Settings
                    </Styled.UserNavLink>
                </Styled.CustomMenuItem>
                <Styled.CustomMenuItem onClick={handleLogout}>
                    <Typography sx={{ padding: '4px 16px' }}>Logout</Typography>
                </Styled.CustomMenuItem>
            </Styled.CustomMenu>
        </>
    );
};

export default UserMenu;
