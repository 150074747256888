import { configureStore } from '@reduxjs/toolkit';
import courseReducer from './courseSlice'
import coursesReducer from './coursesSlice'



export const store = configureStore({
    reducer:
    {
        course: courseReducer,
        courses: coursesReducer
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
