import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { CssBaseline, ThemeProvider } from '@mui/material'
import './index.css'

import theme from './theme/index'
import App from './App'
import { initOIDC } from './api/openid'
import './assets/averta.css' // Averta font import
import AppWrapper from './AppWrapper'
import { store } from './redux/store'

initOIDC()

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Router>
				<AppWrapper>
					<App />
				</AppWrapper>
			</Router>
		</ThemeProvider>
	</Provider>
)
