import { createTheme } from '@mui/material/styles';
import AvertaWoff from '../assets/fonts/averta-regular-webfont.woff';
import AvertaBoldWoff from '../assets/fonts/averta-bold-webfont.woff';
import AvertaSemiBoldWoff from '../assets/fonts/averta-semibold-webfont.woff';

const averta = {
  fontFamily: 'Averta',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${AvertaWoff}) format('woff')
  `,
};

const avertaSemiBold = {
  fontFamily: 'Averta',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${AvertaSemiBoldWoff}) format('woff')
  `,
};

const avertaBold = {
  fontFamily: 'Averta',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'bold',
  src: `
    url(${AvertaBoldWoff}) format('woff')
  `,
};

/* eslint-disable */
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    ss: true;
    smm: true;
    sm: true;
    mdm: true;
    md: true;
    mlg: true;
    lg: true;
    mxl: true;
    xl: true;
    hr: true; // Add this breakpoint
    k4: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      ss: 350,
      smm: 500,
      sm: 600,
      mdm: 800,
      md: 900,
      mlg: 1000,
      lg: 1200,
      mxl: 1350,
      xl: 1536,
      hr: 2000,
      k4: 3100,
    },
  },
  typography: {
    fontFamily: 'Averta, sans-serif',
    fontSize: 16,
    body1: {
      fontSize: '1rem',
      lineHeight: 1.7,
    },
    body2: {
      fontSize: '1.125rem',
      lineHeight: 1.7,
    },
    subtitle1: {
      fontSize: '0.9rem',
      lineHeight: 1.857,
      color: '#919191',
    },
    subtitle2: {
      fontSize: '1rem',
      lineHeight: 1.857,
      color: '#55C1E2',
    },
    h1: {
      fontSize: '3.5rem',
      // @ts-ignore
      fontWeight: '600',
      color: '#424242',
    },
    h2: {
      fontSize: '3rem',
      color: '#55C1E2',
    },
    h3: {
      fontSize: '2.5rem',
    },
    h4: {
      fontSize: '1.875rem'
    },
    h5: {
      fontSize: '1.5rem',
      color: '#55C1E2',
    },
    h6: {
      fontSize: '0.875rem',
      lineHeight: 1.857,
      primary: '#919191',
      secondary: '#55C1E2'
    },
    caption: {
      color: '#919191',
      fontSize: '7px',
    },

  },
  palette: {
    primary: {
      main: '#55C1E2',
      dark: '#34b5dc',
    },
    secondary: {
      light: '#fff',
      main: '#919191',
    },
    text: {
      secondary: '#919191'
    },
  },
  // @ts-ignore
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // @ts-ignore
        '@font-face': [averta, avertaSemiBold, avertaBold],
        body: {
          backgroundColor: '#fff',
          fontSize: '1rem',
          lineHeight: 1.7,
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
    },
    MuiFab: {
      primary: {
        color: '#fff',
      },
    },
  },
})

export default theme;