import React, { Suspense, lazy } from 'react'
import styled from 'styled-components'
import { Route, Routes } from 'react-router-dom'
import Loader from './shared/Loader/Loader'
import Header from './components/Header/Header'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import AppRouteNames from './constants/route'
import OpenID from './components/OpenID/OpenID'

const Container = styled('div')({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	allignItems: 'flex-start'
})

const Content = styled('div')({
	paddingTop: '80px',
	flex: '1 0 auto',

	'& .center-container': {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	}
})

const Courses = lazy(() => import('./pages/Courses/Courses'))
const Course = lazy(() => import('./pages/Course/Course'))
const Topic = lazy(() => import('./pages/Topic/Topic'))

const App = () => (
	<OpenID>
		<Container className='App'>
			<ScrollToTop />
			<Header />
			<Content className='content'>
				<Suspense fallback={<Loader />}>
					<Routes>
						<Route path={AppRouteNames.home} element={<Courses />} />
						<Route path={AppRouteNames.learn} element={<Courses />} />
						<Route path={`${AppRouteNames.course}`} element={<Course />} />
						<Route path={`${AppRouteNames.topic}`} element={<Topic />} />
					</Routes>
				</Suspense>
			</Content>
		</Container>
	</OpenID>
)

export default App
