import { AppBar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'

export const StyledAppBar = styled(AppBar)(props => ({
	flexDirection: 'row',
	padding: 0,
	background: '#fff',
	position: 'fixed',
	alignItems: 'center',
	overflow: 'hidden',
	zIndex: 1500,

	'& a.nav_bar__logo': {
		margin: 0,
		'&:hover': {
			backgroundColor: 'none'
		}
	},

	'& #menu-wrap': {
		display: 'flex'
	},

	'& .header__link': {
		whiteSpace: 'nowrap',
		marginRight: '20px',
		padding: '1.25rem 2.1875rem 1rem',
		textDecoration: 'none',
		borderRadius: '4px',
		border: `1px solid ${props.theme.palette.primary.main}`,
		lineHeight: 1,
		letterSpacing: '.875px',
		fontSize: '.75rem',
		fontWeight: 600,
		textTransform: 'uppercase'
	},

	'& .search-box': {
		marginLeft: 'auto'
	},

	'& .search-box input': {
		width: '120px',
		padding: '15.5px 14px'
	},

	'& #overlay-background': {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: 'rgba(0, 0, 0, 0.5)',
		visibility: 'hidden',
		opacity: 0,
		transition: '0.3s',
		'z-index': '-1'
	},

	[props.theme.breakpoints.down('sm')]: {
		padding: '5px 0',

		'& #menu-wrap': {
			position: 'fixed',
			top: 0,
			left: '-100%',
			bottom: 0,
			background: '#fff',
			width: '250px',
			flexDirection: 'column',
			padding: '30px 20px',
			transition: '0.3s',
			'z-index': '100',

			'& a': {
				margin: '10px 0'
			}
		},

		'& #overlay-background.state-visible': {
			visibility: 'visible',
			opacity: 1,
			transition: '0.3s'
		},

		'& #menu-wrap.state-visible': {
			left: 0,
			transition: '0.3s'
		},

		'& .mobile-menu-icon': {
			display: 'flex',
			color: '#000'
		},

		'& .search-box fieldset': {
			borderWidth: '0'
		},

		'& .search-box #courses-search': {
			width: 0,
			paddingRight: 0,
			transition: '0.3s'
		},

		'& .search-box.animation-search-box #courses-search': {
			width: '110px',
			paddingRight: '14px',
			paddingLeft: '0'
		},

		'& .search-box.animation-search-box fieldset': {
			borderWidth: '1px'
		},

		'& .hide-login': {
			display: 'none'
		},

		'& .header__link': {
			marginRight: '16px',
			padding: '1.25rem 1.5rem 1rem'
		}
	}
}))

export const AppLogo = styled(NavLink)(() => ({
	display: 'flex',
	width: '50px',
	padding: '15px 0',

	'& img': {
		width: '100%',
		height: '100%',
		objectFit: 'contain'
	}
}))

export const AppLogoExternal = styled('a')({
	display: 'flex',
	width: '50px',
	padding: '15px 0',

	'& img': {
		width: '100%',
		height: '100%',
		objectFit: 'contain'
	}
})

export const HeaderLink = styled(Typography)(props => ({
	margin: '0 10px',
	textDecoration: 'none',
	lineHeight: 1,
	color: props.theme.palette.primary.main,

	'&.active, &:hover': {
		color: props.theme.palette.primary.main
	}
}))

export const HeaderExLink = styled('a')(props => ({
	margin: '0px 0px',
	textDecoration: 'none',
	cursor: 'pointer',
	lineHeight: 1,
	color: props.theme.palette.secondary.main,

	'&.active, &:hover': {
		color: props.theme.palette.primary.main
	}
}))
