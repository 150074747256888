/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagName } from '../enums';

const initialState: { courses: Course[], filteredCourses: Course[] } = { courses: [], filteredCourses: [] }
const coursesSlice = createSlice({
    name: 'CoursesSlice',
    initialState,
    reducers: {
        addCourses: (state, action: PayloadAction<Course[]>) => {
            state.courses = action.payload
            return state
        },
        addFilteredCourses: (state, action: PayloadAction<Course[]>) => {
            const sortedFilteredCourses = [...action.payload].sort((course1: Course, course2: Course) => {
                const levelDifference = course1.level - course2.level
                if (levelDifference !== 0) {
                    return levelDifference
                }
                const tagForCourse1 = course1.tags.find((tag: Tag) => tag.slug.toLowerCase() === TagName.Featured.toLowerCase())
                const tagForCourse2 = course2.tags.find((tag: Tag) => tag.slug.toLowerCase() === TagName.Featured.toLowerCase())
                if (tagForCourse1 && !tagForCourse2)
                    return -1 // if course1 has featured tag but course2 does not have, then, keep course1 as first item
                if (!tagForCourse1 && tagForCourse2)
                    return 1 // if course2 has featured tag but course1 does not have, then, keep course2 as first item
                return course1.title.localeCompare(course2.title)
            })
            state.filteredCourses = sortedFilteredCourses
            return state
        }
    },
});
export const { addCourses, addFilteredCourses } = coursesSlice.actions;
export default coursesSlice.reducer;
