import { CircularProgress, Typography } from '@mui/material'
import SecDimLogo from '../../assets/icons/logo.svg'
import * as Styled from './LoaderStyles'

function Loader({ className, overlay = "false", message = '' }: LoadingProps) {
	// TODO: Rework this, at some point this has become absolute? it should not be absolute
	return (
		<div className={className}>
			<Styled.LoaderInnerWrapper overlay={overlay.toString()}>
				<Styled.LogoWrapper>
					<img src={SecDimLogo} alt='SecDim Logo' />
				</Styled.LogoWrapper>
				<CircularProgress />
				<Typography>{message}</Typography>
			</Styled.LoaderInnerWrapper>
		</div>
	)
}

export default Loader
