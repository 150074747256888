import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import styled from 'styled-components'

function AppWrapper({ children }: AppWrapperProps) {
	const client = new QueryClient()
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
		>
			<QueryClientProvider client={client}>
				{children}
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</GoogleReCaptchaProvider>
	)
}

export default styled(AppWrapper)``
