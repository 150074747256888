import React, { useContext } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import UserMenu from '../UserMenu/UserMenu';
import * as Styled from './GreetingStyles';
import { handleSignIn, OpenIDContext } from '../../api/openid';

const Greeting = ({ isAnimated, tryingSilentLogin = false }: GreetingProps) => {
  const { authenticated } = useContext(OpenIDContext)

  return (
    <Styled.StyledWrapperUserMenu
      className={`wrapper_usermenu ${isAnimated ? 'hide-login' : ''}`}
    >
      {authenticated && <UserMenu />}
      {!authenticated && tryingSilentLogin && (
        <Styled.SignInLoadingButton variant='outlined' onClick={handleSignIn}>
          <LoadingButton id="signInLoadingIcon" component="span" loading sx={{ position: 'absolute', left: 0 }} />Sign In
        </Styled.SignInLoadingButton>
      )}
      {!authenticated && !tryingSilentLogin && (
        <Styled.SignInButton variant='outlined' onClick={handleSignIn}>
          Sign In
        </Styled.SignInButton>
      )}
    </Styled.StyledWrapperUserMenu>
  );
};

export default Greeting;
