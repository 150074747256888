import { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { Box, Grid, Toolbar, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UserManager, UserManagerSettings } from 'oidc-client-ts';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/icons/short-logo.svg';
import SearchField from '../fields/SearchField/SearchField';
import AppRouteNames from '../../constants/route';
import Greeting from '../Greeting/Greeting';
import { HeaderLink, HeaderExLink, StyledAppBar, AppLogoExternal } from './HeaderStyles';
import learnBackendRequestHandler from '../../api/requestHandler';
import { OpenIDContext } from '../../api/openid';
import { addFilteredCourses } from '../../redux/coursesSlice';

const Header = () => {
    const { authenticated } = useContext(OpenIDContext)
    const [signInLoading, setSignInLoading] = useState(sessionStorage.getItem("silentLoginAlreadyAttempted") !== "true")
    const code = new URLSearchParams(window.location.search).get('code')
    const [isAnimated, setIsAnimated] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const search = useRef();
    const menu = useRef();
    const location = useLocation();
    const dispatch = useDispatch();
    const smSize = useMediaQuery('(max-width: 600px)')
    const courses = useSelector((state: any) => state.courses.courses)
    const [searchValue, setSearchValue] = useState('');


    const silentSignInReload = useCallback(() => {
        learnBackendRequestHandler('heartBeat').then(hbResp2 => {
            if ((hbResp2.data as any).is_logged_in) {
                window.location.reload();
            }
            setSignInLoading(false)
        })
    }, [])

    useEffect(() => {
        if (signInLoading) {
            learnBackendRequestHandler('heartBeat').then(async hbResp2 => {
                if (!(hbResp2.data as any).is_logged_in && !code && !authenticated) {
                    // the following settings are for silent login
                    const clientID = ((await learnBackendRequestHandler('openIdClient')).data as any).client_id
                    const userManagerSettings: UserManagerSettings = {
                        authority: `${process.env.REACT_APP_SECDIM_ID_BACKEND}openid`,
                        client_id: clientID, // need to get this in dev env somehow...
                        redirect_uri: window.location.origin,
                        post_logout_redirect_uri: `${process.env.REACT_APP_SECDIM_ID_FRONTEND}account/logout`,
                        response_type: 'code',
                        scope: 'openid email',
                        filterProtocolClaims: true,
                        loadUserInfo: true,
                        silent_redirect_uri: window.location.origin,
                        silentRequestTimeoutInSeconds: 5
                    }
                    const userManager: UserManager = new UserManager(userManagerSettings)
                    userManager.signinSilent().then(() => {
                        silentSignInReload()
                        sessionStorage.setItem("silentLoginAlreadyAttempted", "true")
                    }).
                        catch((error: Error) => {
                            // eslint-disable-next-line
                            console.error(error)
                            silentSignInReload()
                            sessionStorage.setItem("silentLoginAlreadyAttempted", "true")
                        })
                }
            })
        }
    }, [authenticated, code, signInLoading, silentSignInReload])

    const searchCourses = (e: any) => {
        dispatch(
            addFilteredCourses(
                courses.filter(
                    (course: Course) => course.title.toUpperCase().includes(e.target.value.toUpperCase()))
            )
        )
        setSearchValue(e.target.value)
    };

    const handleClick = (e: any) => {
        if (search.current && !(search.current as any).contains(e.target)) {
            setIsAnimated(false);
        }
        if (menu.current && !(menu.current as any).contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const animateSearchBox = () => {
        setIsAnimated(true);
    };

    const openMenu = () => {
        setIsOpen(true);
    };

    const closeMenu = () => {
        setIsOpen(false);
        window.location.href = `${AppRouteNames.home}`
    };

    return (
        <div>
            <StyledAppBar>
                <Grid container wrap="nowrap" alignItems="center">
                    <Toolbar>
                        <AppLogoExternal className="app-logo" href='https://secdim.com/' target='_blank'>
                            <img src={logo} alt="SecDim" title="SecDim" />
                        </AppLogoExternal>
                    </Toolbar>
                    {smSize && <MenuIcon className="mobile-menu-icon" onClick={openMenu} />}
                    <Box
                        id="menu-wrap"
                        className={isOpen ? 'state-visible' : ''}
                        ref={menu}
                        mt={isOpen ? 0 : 1.2}
                    >
                        <HeaderLink onClick={closeMenu} >
                            Learn
                        </HeaderLink>
                        <Box mt={3} ml={isOpen ? 0 : 0.35} />
                        <HeaderExLink href='https://play.secdim.com'>Play</HeaderExLink>
                        <Box mt={3} ml={isOpen ? 0 : 2.25} />
                        <HeaderExLink href='https://discuss.secdim.com/'>Discuss</HeaderExLink>
                        <Box mt={3} ml={isOpen ? 0 : 1.65} />
                        <HeaderExLink href='https://secdim.com/workshop/'>Workshops</HeaderExLink>
                    </Box>
                    <Box
                        id="overlay-background"
                        className={isOpen ? 'state-visible' : ''}
                        onClick={closeMenu}
                    />
                </Grid>
                {(location.pathname.search('learn') !== -1 || location.pathname === "/") && (
                    <Box
                        className={`search-box ${isAnimated ? 'animation-search-box' : ''}`}
                        ref={search}
                        mr={1}
                    >
                        <SearchField
                            id="courses-search"
                            placeholder="Search for unit"
                            value={searchValue}
                            onChange={searchCourses}
                            onClick={animateSearchBox}
                        />
                    </Box>
                )}
                <Greeting
                    isAnimated={isAnimated}
                    tryingSilentLogin={signInLoading}
                />
            </StyledAppBar>
        </div>
    );
};

export default Header;
